import { Col, Container, Row} from 'react-bootstrap';
import Items from './Items';
import BotonCustomLink from '../../components/all/BotonCustomLink';
import useTemas from '../../hooks/useTemas';

const Disputas=()=>{

    const temas = useTemas()

   return(
        <Container className='container-body-2' style={{padding: '50px 0px 60px 0px'}}>
            <Row>
                <Col md={12} className='text-center mt-3 disputas' data-aos="fade-up" data-aos-offset="50"  data-aos-duration="1000">    
                    <h2>Centro de Consultas y Reclamos</h2>
                </Col>
                <Col md={12} className='text-center mt-3 disputas' data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">    
                    <p>Entendemos que a veces pueden ocurrir transacciones que no reconozcas como propias, o quieras desconocer cargos por algún problema con la entrega de la contraprestación (compra de productos, suscripciones a servicios, o accesos a recursos) por lo cual pagaste online vía Payfun con tu tarjeta de crédito o débito.
                    Si bien es posible que aún no hayas tenido ninguna, saber esto te ayudará a estar mejor preparado en caso de que ocurra.</p>
                </Col>
                <Col md={12} className='text-center mt-5' data-aos="fade-up" data-aos-offset="50" data-aos-delay="250" data-aos-duration="1000">
                    <BotonCustomLink text='Seguimiento de mi reclamo' link='/reclamos/seguimiento-de-reclamos' tipo='white'/><br/><br/><br/>
                </Col>
                <Col md={12} className='text-center mt-3 disputas' data-aos="fade-up" data-aos-offset="50"  data-aos-duration="1000">    
                    <h3>Realizar un reclamo sobre</h3>
                </Col>
                <Col md={12} className='text-center disputas' data-aos="fade-up" data-aos-offset="50"  data-aos-duration="1000">    
                    <p><i>(Escoge abajo el item sobre el que deseas hacer un reclamo)</i></p>
                </Col>

                {temas.map((reclamo, index) =><Items key={`tema-${index}`} tema={reclamo}/>)}
            
                

            </Row>
        </Container>
    )
}

export default Disputas