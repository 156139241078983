import {Container,Col,Row} from 'react-bootstrap';
import logoFooter from '../../components/img/pf-logo.png';
import dataAfip from '../../components/img/DATAWEB.jpg';
import logoCace from '../../components/img/logo-cace-desktop2.svg';
import logoCamara from '../../components/img/Logo_Header.webp';
import logoBcra from '../../components/img/Bcra_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquareInstagram,
    faLinkedin,
    faSquareFacebook,
    faSquareTwitter
  } from '@fortawesome/free-brands-svg-icons';




export default function Foot(){
    return(
        <>
        <div className='containerFluid footer'>
            <Container className='max-foot'>
                <Row>
                <Col md='5' className='block'>
                <strong>Grupo Paynet Business</strong>
                <p>Grupo Paynet Business, es un grupo de Empresas y Entidades enfocadas en el Desarrollo de Modelos de Negocios Basados en Tecnología e Internet.</p>
                <p>El grupo es integrado por las siguientes entidades/empresas:</p>
                <p>
                    <a href='https://iprocom.com.ar/' target='_blank' rel='noopener noreferrer'>Fundación IPROCOM</a> - <a href='https://criterionet.com/' target='_blank' rel='noopener noreferrer'>Criterionet</a> - <a href='https://emprendear.com.ar/' target='_blank' rel='noopener noreferrer'>EmprendeAR Crowdfunding</a> - <a href='https://payfun.com.ar/' target='_blank' rel='noopener noreferrer'>PayFun</a> - <a href='https://investate.ar/' target='_blank' rel='noopener noreferrer'>Investate </a></p>
                </Col>


                <Col md='5' className='block'>
                    <Row>
                        <Col md='12' className='text-center'>
                            <p>Miembro activo de 
                                <a target='_blank' href='https://www.cace.org.ar/socios-payfun' rel="noreferrer">
                                    <img  src={logoCace} width='70' height='auto' alt='cace'/>
                                </a>
                                <a target="_blank" href='https://camarafintech.com.ar/socios-web/' rel='noreferrer'>
                                    <img  src={logoCamara} width="130" height="auto" alt='camarafintech'/>
                                </a>
                            </p>
                        </Col>
                    </Row>
                    <Row className='text-center' style={{marginTop:'20px'}}>
                        <Col md='3' className='text-center'>
                            <a target='_blank' href='https://www.bcra.gob.ar/' rel='noreferrer'>
                                <img  src={logoBcra} width='75' height='auto' alt='bcra'/>
                            </a>
                        </Col>
                        <Col md='9'>
                            <p>PayFun se encuentra registrada bajo el número 33.556 en el registro de Proveedores de Servicios de Pago del BCRA</p>
                        </Col>
                    </Row>
                </Col>
                <Col md='2' className='block'>
                    <div className='text-center'>
                        <img src={logoFooter} alt='logoFooter' width='120' height='auto'/>
                    </div>
                <Col md='12' className='text-center'>
                    <a target='_blank' href='https://www.instagram.com/payfunok' rel='noreferrer'>
                        <FontAwesomeIcon icon={faSquareInstagram} className='icono'/>
                    </a>
                    <a target='_blank' href='https://www.facebook.com/payfunOK' rel='noreferrer'>
                        <FontAwesomeIcon icon={faSquareFacebook} className='icono'/>
                    </a>
                    <a target='_blank' href='https://www.linkedin.com/company/payfun-medios-de-pago-online/' rel='noreferrer'>
                        <FontAwesomeIcon icon={faLinkedin} className='icono'/>
                    </a>
                    <a target='_blank' href='https://twitter.com/payfunok1' rel='noreferrer'>
                        <FontAwesomeIcon icon={faSquareTwitter} className='icono'/>
                    </a>
                </Col>
                <Col md='12' className='text-center'>
                    <a href='http://qr.afip.gob.ar/?qr=Ft_f-qICSRm4rE3SDgzrKQ,,' target='_F960AFIPInfo' rel='noopener noreferrer'>
                        <img src={dataAfip} width='60' border='0' alt='dataweb afip'/>
                    </a>
                </Col>
                </Col>

                </Row>
            </Container>        
        </div>
        <div className='containerFluid pie'>
        <p>
        © 2024. PayFun. Un servicio de PayNet Bussines S.A.S. Integramos Negocios Digitales con Medios de Pago. Oficina administrativa: Av. Rafael Núñez 3835, oficina 5, Cerro de las Rosas, C.P.X5009CFF Córdoba, Argentina. En lo que respecta al Procesamiento de Pagos para clientes, PayFun se limita a ofrecer servicios de pago y no se encuentra autorizado a operar como entidad financiera por el BCRA. Los fondos depositados en cuentas de pago virtuales de los clientes no constituyen depósitos en una entidad financiera, ni cuentan con ninguna de las garantías que tales depósitos puedan gozar de acuerdo con la legislación y reglamentación aplicables en materia de depósitos en entidades financieras.
        </p>

        </div>
        </>
    )
}