import '../Services.css';
import HeadImage from '../../../components/img/photos/services/boton-en-pagina-web.png'
import Headboard from '../../../components/services/Headboard';
import { Col, Container, Row } from 'react-bootstrap';
import ServiceCustomCard from '../../../components/services/ServiceCustomCard';
import BotonCustom from '../../../components/all/BotonCustom';


const SitioWeb = () =>{
    const itemsUno=[
        {texto:'Agregá un código o un plugin en tu página web.'},
        {texto:'Nos integramos fácilmente en tu plataforma.'},
    
    ]
    const itemsDos=[
        {texto:'Ofrecé una alternativa fácil y rápida a tus clientes.'},
        {texto:'Disponemos de plugins para las principales plataformas de E-commerce.'},
    
    ]

    return (
        <div id='services' className='containerFluid'>

            <Headboard title={`Pagos en tu sitio web`} subtitle={`Cobrá en tu página web con un botón\nde pago inserto en tu tienda online`} button={`Quiero cobrar en mi sitio web`} image={HeadImage}/>
            <Container className='container-body-2' style={{paddingTop:'80px', paddingBottom:'80px'}}>
            <Row>   
            <Col md={12}>
                <ServiceCustomCard title={`Sumá un botón de pago en tu sitio web`} list={itemsUno} marks='on'/>
            </Col>
            <Col md={12} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000"><br/><br/>
                <BotonCustom text='Quiero cobrar en mi sitio web' tipo='blue'/><br/><br/>
            </Col>
            <Col md={12}>
                <ServiceCustomCard title={`La solución ideal para tu E-commerce o tienda virtual`} list={itemsDos} ecommerce='on'/>
            </Col>
            <Col md={12} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000"><br/><br/>
                <BotonCustom text='Quiero cobrar en mi sitio web' tipo='blue'/><br/><br/>
            </Col>
            </Row>
            </Container>
    
        
            
            
        </div>
    )
}

export default SitioWeb