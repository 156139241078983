import Headboard from '../../../components/solutions/Headboard'
import Sublock from '../../../components/solutions/Sublock'
import HeadImage from '../../../components/img/photos/solutions/consorcio.png'
import { Container,Row,Col } from 'react-bootstrap'
import CustomCard from '../../../components/solutions/CustomCard'
import BackgrounWhiteDiv from '../../../components/img/solutions/background-cards.jpg';
import MultiCard from '../../../components/solutions/MultiCard'
import Tarjetas from '../../../components/solutions/Tarjetas'


const Consorcios = () =>{
    return (
        <div id='solutions' className='containerFluid'>
            <Headboard title={`Consorcios`} image={HeadImage}/>
            
            <Sublock 
            title = {`¿Querés facilitar el cobro de tus alquileres,\nexpensas, entre otros?`}
            text = {`Te ofrecemos una plataforma fácil y segura para la recaudación de expensas.`}
            />

            <Container className='container-body-2 neutral-div'>
                <CustomCard title={`¿Ya contás con un sistema de gestión,\nde facturación y cobro?`} text={`Disponemos de potentes y diversas API's que se integran\nfácilmente a tu plataforma.`} align='center' button='off'/>
            </Container>

            <div className='containerFluid white-div' style={{backgroundImage:`url(${BackgrounWhiteDiv})`}}>
                <Container className='container-body-2' >
                    <Row>
                    <Col md={6}>    
                    <CustomCard title={`Página web adaptada\na tus inquilinos`} text={`Agregá a tu página web un botón de pago\npara agilizar tus cobros.\n\n`} align='left' button='off'/>
                    </Col>
                    <Col md={6}>    
                    <CustomCard title={`¿No contás con una página\nweb propia?`} text={`Nosotros nos encargamos de crear una\nlanding con botón de pago para tus\ninquilinos.`} align='left' button='off'/>
                    </Col>
                    <Col md={12}>    
                    <CustomCard title={`Gestioná la mora eficazmente`} text={`PayFun te permite cobrar todas las cuotas, aún cuando\nestén vencidas.`} align='center' button='off'/>
                    </Col>
                    </Row>
                </Container>
            </div>

            <Container className='container-body-2 neutral-div'>
                <CustomCard title={`Cobrá con débito automático`} text={`Con PayFun, gestioná rápidamente tus pagos recurrentes.`} align='center' button='on'/>
            </Container>

            <MultiCard/>
          
            <Tarjetas text='Quiero aumentar mi recaudación'/>
            
        </div>
            )
}

export default Consorcios