import {Card} from 'react-bootstrap';
import BotonSolicitud from '../all/BotonSolicitud'

export default function CustomCard({title,text,align,button}){
    const boton = button;
    return(
         <Card className='border-0 customCard'  data-aos="fade-up" data-aos-offset="200" data-aos-duration="1000">
                <Card.Body className = {align==='center'?'text-center':''}>
                    <div  data-aos="fade-up" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                    {boton === 'on'? <h2 className="parrafo">{title}</h2>:<h3 className="parrafo">{title}</h3>}    
                    </div>    
                    <div  data-aos="fade-up" data-aos-offset="200" data-aos-delay="1000" data-aos-duration="1000">
                        
                        {text.includes('(Totems)') !== false ? 
                        <p className="parrafo texto">Ofrecé a tus asociados un puesto de recaudación interactiva y autogestionable (<a href="/servicios/totems-interactivos">Totems</a>) en el local físico que requieras.</p>
                        :
                        <p className="parrafo texto">{text}</p>}
                        {boton === 'on' && <BotonSolicitud positivo='true'/>}
                        </div>    
                </Card.Body>
            </Card>
        
    ) 
}