import { useState } from 'react';
import { Col, Container, Row,Button,Collapse} from 'react-bootstrap';
import { Link } from 'react-router-dom';


export default function PreguntasFrecuentes(){

    const [item0, setItem0] = useState(false);
    const [item1, setItem1] = useState(false);
    const [item2, setItem2] = useState(false);
    const [item3, setItem3] = useState(false);
    const [item4, setItem4] = useState(false);
    const [item5, setItem5] = useState(false);
    const [item6, setItem6] = useState(false);
    const [item7, setItem7] = useState(false);
    const [item8, setItem8] = useState(false);
    const [item9, setItem9] = useState(false);

    return(
        <div id='terminos' className='containerFluid'>
            <Container className='container-body-2'>
                <Row>
                    <Col md={12} className='text-center mb-2 mt-5'>
                        <h2>Preguntas Frecuentes</h2>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mt-5 mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem0(!item0)} aria-controls="example-collapse-text" aria-expanded={item0} style={{textAlign:'left'}}>
                    {item0===true?'▲':'▼'} ¿Qué necesito para ser cliente de PayFun?¿Es gratis la Cuenta?
                    </Button>
                    <Collapse in={item0} className='faq-body'>
                        <div id="faq-0" >
                        Es muy fácil de usar PayFun, para abrir una cuenta con nosotros, solo necesitás:<br/><br/>
                        <ul>
                            <li>Un número de teléfono celular</li>
                            <li>Una cuenta de email válida</li>
                            <li>Una cuenta bancaria argentina</li>
                            <li>Un DNI</li>
                            <li>Un número de CUIT</li>
                            <li>Documentación respaldatoria de tu posición fiscal en AFIP (la cual deberás adjuntar al solicitar el alta)</li>
                        </ul>
                        Con estos datos completás tu registro y luego de que validemos la información que nos diste, podrás crear una cuenta totalmente gratis para empezar a probar nuestras herramientas de cobro. <a href='https://prealtas.payfun.com.ar' target='_selft'>ACCEDE AL FORMULARIO DE PRE-ALTA</a>
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem1(!item1)} aria-controls="example-collapse-text" aria-expanded={item1} style={{textAlign:'left'}}>
                    {item1===true?'▲':'▼'} ¿Qué tipos de cobro puedo hacer con PayFun?
                    </Button>
                    <Collapse in={item1} className='faq-body'>
                        <div id="faq-1" >
                        Con PayFun podés gestionar distintos tipos de cobros según tus necesidades y las de tus clientes:<br/><br/>
                        <ul>
                            <li><strong>Cobro con órdenes de pago:</strong> desde tu panel de control, podrás generar muy fácilmente órdenes de pago (un link) para enviar por email, Whatsapp o redes sociales. Tus clientes al recibir ese link, clickearán en él e ingresarán a una ventana web en la cual colocarán los datos de su tarjeta y te realizarán el pago, en cualquier lugar del mundo en que se encuentren.</li>
                            <li><strong>Cobros recurrentes con débitos automáticos:</strong> Este sistema de cobro se usa para cobrar servicios de tipo suscripción o pagos periódicos (cuotas, abonos, etc.) donde se necesite enviar todos los meses una orden de cobro en una fecha determinada. Desde tu panel, podrás configurar muy fácilmente este tipo de cobros automático, así como débitos automáticos para las distintas tarjetas de crédito.</li>
                            <li><strong>Cobros vía mensajería o Whatsapp:</strong> podés cobrar por cualquier canal digital, para que no te quedes sin tu dinero y puedas vender más.</li>
                            <li><strong>Integración de PayFun en un carro de compras de un sitio web:</strong> Podrás también integrar nuestra herramienta de pago en forma de plugin o módulo para operar en las plataformas de E-commerce más usadas: WordPress con wooCommerce o Prestashop. En nuestro sitio podrás contar con toda la documentación para poder realizar esta integración por tu cuenta con tus desarrolladores, o con nuestra ayuda, como prefieras. ¿No tenés un sitio de E-commerce y querés uno para cobrar con PayFun? contactanos, tenemos la solución más conveniente para vos con nuestros expertos en el tema.</li>
                        </ul>
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="150" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem2(!item2)} aria-controls="example-collapse-text" aria-expanded={item2} style={{textAlign:'left'}}>
                    {item2===true?'▲':'▼'} ¿Puedo cobrar con financiación de planes AHORA 12,18,24, etc? ¿Puedo usar mis propios números de comercio?
                    </Button>
                    <Collapse in={item2} className='faq-body'>
                        <div id="faq-2" >
                        Si dispones de números de comercio (MID:Merchant ID) otorgado por cualquiera de los adquirentes actuales del sistema (Prisma o First Data), y que a su vez por el rubro o CUIT que poseas, estés habilitado a operar con planes ahora, podemos activar tu cuenta con esos números de comercio para que puedas financiar tus ventas a clientes, con los planes AHORA del gobierno. Asimismo, si tenés números de comercio propios y querés recibir el dinero en 48hs (para débito) y 10 días hábiles (para crédito), por más que no operes los planes AHOR, también podés usar PayFun para cobrar con todas las tarjetas que tengas habilitadas por los adquirentes.
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem3(!item3)} aria-controls="example-collapse-text" aria-expanded={item3} style={{textAlign:'left'}}>
                    {item3===true?'▲':'▼'} Si uso PayFun para cobrar con tarjetas ¿Cuándo cobro mi dinero?
                    </Button>
                    <Collapse in={item3} className='faq-body'>
                        <div id="faq-3" >
                        A continuación verás los plazos de liquidación, según el tipo de venta que hayas realizado con las diferentes tarjetas. cuando tu venta en nuestro panel figure como liquidada (ícono azul), tu dinero será depositado en la cuenta bancaria que hayas indicado en tu registro, dentro de los 3 días hábiles posteriores a dicha liquidación.<br/><br/>
                        <ul>
                            <li><strong>Tarjetas de débito:</strong> en 4 días hábiles.</li>
                            <li><strong>Tarjetas de crédito en cuotas:</strong> 4 días hábiles.</li>
                            <li><strong>Tarjetas de crédito en un pago:</strong> 12 días hábiles.</li>
                            <li><strong>Tarjeta Naranja en un pago:</strong> día 15 (o siguiente hábil) del mes próximo, a partir del cierre del período.</li>
                            <li><strong>Tarjeta Naranja (Plan Z):</strong> día 15 (o siguiente hábil) del mes próximo al cierre del período, más 30 días.</li>
                        </ul><br/>
                        <i>Nota: Los importes a liquidar podrán ser pasibles de retenciones impositivas, según cual sea la situación fiscal y jurisdicción donde opere tu negocio, empresa o entidad.</i>
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="250" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem4(!item4)} aria-controls="example-collapse-text" aria-expanded={item4} style={{textAlign:'left'}}>
                    {item4===true?'▲':'▼'} ¿Cuáles son las opciones o herramientas de cobro disponibles con PayFun?
                    </Button>
                    <Collapse in={item4} className='faq-body'>
                        <div id="faq-4" >
                        ¡Todas! con PayFun contás con lo que técnicamente se llama MultiPOS, que no es otra cosa que poder vender y cobrar en tu local físico desde una PC, Tablet, o dispositivo móvil, y en cualquier lugar en que se encuentre tu cliente. También podés cobrar con "tarjeta presente", que es cuando el cliente está en tu local físico, y te quiere pagar con tarjeta de crédito o débito, ya sea en un pago o en cuotas (con adhesión a débito automático). Vos solo tenés que ingresar a tu cuenta, en tu panel de PayFun en nuestra web, cargás los datos de la tarjeta, el monto y la cantidad de cuotas, y el sistema te valida el pago. ¡Y listo, ya cobraste!
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="300" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem5(!item5)} aria-controls="example-collapse-text" aria-expanded={item5} style={{textAlign:'left'}}>
                    {item5===true?'▲':'▼'} No tengo plataforma de E-commerce y me interesa vender y cobrar online ¿Cómo hago?
                    </Button>
                    <Collapse in={item5} className='faq-body'>
                        <div id="faq-5" >
                        En caso de que no cuentes con un sitio web o plataforma de E-commerce y quieras tener una para poder vender y cobrar online, podemos ayudarte. contamos con especialistas en E-commerce, tanto en consultoría, marketing digital y desarrollo web, para poder proponerte una plataforma o aplicación que te permita vender y cobrar online con PayFun. Si esta opción te interesa, solo contáctanos para que hablemos del tema.
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="350" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem6(!item6)} aria-controls="example-collapse-text" aria-expanded={item6} style={{textAlign:'left'}}>
                    {item6===true?'▲':'▼'} ¿Necesito posnet tradicionales para operar con PayFun o mi E-commerce?
                    </Button>
                    <Collapse in={item6} className='faq-body'>
                        <div id="faq-6" >
                        No. No necesitas posnet tradicionales, por lo tanto ahorrás esos costos. Todas las operaciones se validan online, vía web, en tu panel de administración de nuestro sitio.
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="400" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem7(!item7)} aria-controls="example-collapse-text" aria-expanded={item7} style={{textAlign:'left'}}>
                    {item7===true?'▲':'▼'} ¿Cuáles son los recargos por las ventas en cuotas con tarjetas de crédito?
                    </Button>
                    <Collapse in={item7} className='faq-body'>
                        <div id="faq-7" >
                        Eso depende del plan y promociones vigentes de cada tarjeta. Actualmente, todos los planes en cuotas de las distintas tarjetas, tienen un recargo por la financiación. Ese recargo no lo abonás vos sino tu cliente, al momento de elegir el plan de cuotas para realizar el pago, dentro de tu panel de administración de tu cuenta PayFun.
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="450" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem8(!item8)} aria-controls="example-collapse-text" aria-expanded={item8} style={{textAlign:'left'}}>
                    {item8===true?'▲':'▼'} Tengo más de un local o punto de venta ¿Puedo integrarlos a todos?
                    </Button>
                    <Collapse in={item8} className='faq-body'>
                        <div id="faq-8" >
                        Si, por supuesto. Podés dar de alta puntos de ventas (locales) en segundos y de forma muy fácil, de esta forma integrás todas tus cobranzas en un solo panel de control.
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-5 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="500" data-aos-duration="1000">
                    <Button className='faq-button' onClick={() => setItem9(!item9)} aria-controls="example-collapse-text" aria-expanded={item9} style={{textAlign:'left'}}>
                    {item9===true?'▲':'▼'} ¿En concreto qué servicios me ofrece PayFun?
                    </Button>
                    <Collapse in={item9} className='faq-body'>
                        <div id="faq-9" >
                        PayFun te ofrece esta batería de servicios:<br/>
                        <ul>
                            <li>Check-out web, tanto en nuestra página propia de PayFun, como la de tu comercio, empresa, negocio o entidad.</li>
                            <li>Solicitud de Pago (Botón de Pago) u órden de pago para su envío en formato de link mediante e-mail, whatsapp, sms, facebook, etc.</li>
                            <li>MultiPOS para cobro en computadora, tablet o smartphone, con tarjeta presente.</li>
                            <li>Adhesiones para suscriptones/débitos automáticos en tarjeta de crédito y débito.</li>
                            <li>Panel Administrativo Centralizado para el seguimiento de tus operaciones con detalle de cada operación y su estado.</li>
                            <li>Conciliación de transacciones con tarjetas de crédito y débito por parte nuestra.</li>
                            <li>Soporte técnico de mantenimiento y actualización de la plataforma.</li>
                            <li>APIs de integración para desarrolladores.</li>
                            <li>Capacitación funcional inicial.</li>
                            <li>El mejor servicio de asistencia y acompañamiento de Marketing para tu Negocio, para que puedas aumentar facturación o eficiencia en las cobranzas, con el menor costo por operación del mercado.</li>
                        </ul>
                        Todo esto con la integración de gateways de pago validados internacionalmente como operador seguro de transacciones de pago con todas las tarjetas.no hay mucho más que pensar...<Link to='/contacto'>¡Contáctanos ahora!</Link>
                        </div>
                    </Collapse>
                    </Col>
                </Row>
                <br/><br/>
            </Container>
        </div>
    ) 
}